import RoadDemande from "./RoadDemande"
import Customer from "./customer"
import { StatusContract } from "./enums/status-contract.enum"
import User from "./user"

export default class Contract{
    id: number
    demande_id?: number
    status: string
    internal_status: StatusContract | null
    created_at: Date
    updated_at: Date
    carrier_name: string
    purchase_price: number
    selling_price: number
    client_id: number
    code: string
    client_reference: string
    contact: string
    assignment: string
    loading_address?: string;
    loading_postal_code?: string;
    loading_city?: string;
    loading_country?: string;
    loading_date_start?: Date
    loading_date_end?: Date
    delivery_address?: string;
    delivery_postal_code?: string;
    delivery_city?: string;
    delivery_country?: string;
    delivery_date_start?: Date
    delivery_date_end?: Date
    driver?: string
    dangerous_substances: boolean
    quantity: number
    type: string
    client: Customer
    assigned_user?: User
    demande?: RoadDemande

    constructor(data: any) {
        this.id = data.id;
        this.demande_id = data.demande_id;
        this.status = data.status;
        this.internal_status = data.internal_status;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.carrier_name = data.carrier_name;
        this.purchase_price = data.purchase_price;
        this.selling_price = data.selling_price;
        this.client_id = data.client_id;
        this.code = data.code;
        this.client_reference = data.client_reference;
        this.contact = data.contact;
        this.assignment = data.assignment;
        this.loading_address = data.loading_address ? data.loading_address : '';
        this.loading_postal_code = data.loading_postal_code;
        this.loading_city = data.loading_city;
        this.loading_country = data.loading_country;
        this.loading_date_start = data.loading_date_start ? new Date(data.loading_date_start) : undefined;
        this.loading_date_end = data.loading_date_end ? new Date(data.loading_date_end) : undefined;
        this.delivery_address = data.delivery_address ? data.delivery_address : '';
        this.delivery_postal_code = data.delivery_postal_code ? data.delivery_postal_code : '';
        this.delivery_city = data.delivery_city ? data.delivery_city : '';
        this.delivery_country = data.delivery_country ? data.delivery_country : '';
        this.delivery_date_start = data.delivery_date_start ? new Date(data.delivery_date_start) : undefined;
        this.delivery_date_end = data.delivery_date_end ? new Date(data.delivery_date_end) : undefined;
        this.driver = data.driver;
        this.dangerous_substances = data.dangerous_substances;
        this.quantity = data.quantity;
        this.type = data.type;
        this.client = new Customer(data.client);
        this.assigned_user = data.assigned_user ? new User(data.assigned_user) : undefined;
        this.demande = data.demande ? new RoadDemande(data.demande) : undefined;
    }

    getOriginLabel(): string {

        const postalCode = this.loading_postal_code ? `${this.loading_postal_code}` : '';
        const locality = this.loading_city ? `${postalCode ? ', ' : ''}${this.loading_city}` : '';
        const country = this.loading_country ? `${locality ? ', ' : ''}${this.loading_country}` : '';

        return `${postalCode}${locality}${country}`;
    }

    getDestinationLabel(): string {
        
        const postalCode = this.delivery_postal_code ? `${this.delivery_postal_code}` : '';
        const locality = this.delivery_city ? `${postalCode ? ', ' : ''}${this.delivery_city}` : '';
        const country = this.delivery_country ? `${locality ? ', ' : ''}${this.delivery_country}` : '';

        return `${postalCode}${locality}${country}`;
    }

    getColorInternalStatus(): string {
        return '#344054';
        
    }

    getBackgroundColorInternalStatus(): string {
        return '#F9FAFB';
    }

    getBorderColorInternalStatus(): string {
        return '#EAECF0';
    }

    getStatusLabel(): string {
        return this.status
    }

    getCarrierName() {
            if(this.carrier_name && this.carrier_name.length > 8)
                return this.carrier_name.substring(0, 8) + '...';

            return this.carrier_name;
        }
}