import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";

import App from "./App.tsx";
import { AntConf } from "./configs/AntConf";
import { Provider } from "react-redux";
import { store } from "./store/index.ts";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://097189fc2b02ed9a970cf0688fc51107@o4508018095947776.ingest.de.sentry.io/4508263668973648",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.VITE_SENTRY_ENV,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <Provider store={store}>
      <ConfigProvider {...AntConf}>
        <App />
      </ConfigProvider>
    </Provider>
);
