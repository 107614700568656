import { useEffect, useMemo, useState } from "react";
import roadDemandesService from "../../services/road-demandes.service";
import DataPaginate from "../../models/utils/data-paginate";
import useTable from "../../utils/hooks/useTable";
import { columns } from "../../configs/RoadDemandesTableColumns";
import { useNavigate, useSearchParams } from "react-router-dom";
import useDebounce from "../../utils/hooks/useDebounce";
import RoadDemande from "../../models/RoadDemande";
import dateHelper from "../../utils/date.helper";
import Header from "../../components/layout/Header";
import { Button, Flex, Input, Layout, Select, Space, Table } from "antd";
import { Icon } from "../../components/ui/typo/Icon";
import ButtonColumn from "../../components/ui/buttons/button-column";
import StatusRoadCotation from "../../components/core/road-folder/status-road-cotation";
import { Text } from "../../components/ui/typo/Text";
import PanelRoadCotation from "../../components/core/road-folder/panel-road-cotation";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import DrawerFilterRoadQuotation from "../../components/core/commons/drawer-filter/drawer-filter";
import GroupFilter from "../../models/utils/group-filter";
import Filter, { FilterType } from "../../models/utils/filter";
import { StatusRoadDemande } from "../../models/enums/status-road-demande.enum";
import dayjs from 'dayjs';

export default function ListRoadQuotationsPage() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [page, setPage] = useState<number>(searchParams.has('page') ? parseInt(searchParams.get('page') || '1') : 1);
    const [search, setSearch] = useState<string>(searchParams.get('search') || '');
    const { visibleColumns, setVisibleColumns, renderColumns  } = useTable(columns)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(searchParams.has('add') ? true : false);
    const [showFilters, setShowFilters] = useState(false);
    const {value : user} = useAppSelector(state => state.user);
    const [accountId, setAccountId] = useState<number | undefined>(searchParams.get('account_id') ? parseInt(searchParams.get('account_id') || "0") : user?.id);
    const [statusFilter, setStatusFilter] = useState<string | null>(searchParams.get('status'));
    
    useEffect(() => {
        if(searchParams.has('add')) {
            setShowModal(true);
        }

        if (searchParams.has('id')) {
            onEdit(parseInt(searchParams.get('id') || '0'));
        }
    }, [searchParams]);

    const [groupFilters, setGroupFilters] = useState<GroupFilter[]>([
        new GroupFilter({
            label: "Date de la demande de transport",
            filters: [
                new Filter({
                    name: "date_from",
                    label: "Date de début",
                    type: FilterType.DATE,
                    value: searchParams.get('date_from') ? dayjs(searchParams.get('date_from')) : undefined,
                }),
                new Filter({
                    name: "date_to",
                    label: "Date de fin",
                    type: FilterType.DATE,
                    value: searchParams.get('date_to') ? dayjs(searchParams.get('date_to')) : undefined,
                }),
            ],
            formater: function(){
                if (this.filters[0].labelValue && this.filters[1].labelValue) {
                    return `Du ${this.filters[0].labelValue} au ${this.filters[1].labelValue}`
                } else if (this.filters[0].labelValue) {
                    return `Depuis le ${this.filters[0].labelValue}`
                } else if (this.filters[1].labelValue) {
                    return `Jusqu'au ${this.filters[1].labelValue}`
                } else {
                    return ""
                }
            }
        }),
    ]);
    const nbFilters = useMemo(() => {   
        return groupFilters.reduce((acc, group) => {
            return acc + group.filters.filter(f => f.value != null).length;
        }, 0)
    }, [groupFilters]);

    const [cotation, setCotation] = useState<RoadDemande>();


    const filters = useMemo(() => {
        return groupFilters.map(g => {
            const enabledFilters = g.filters.filter(f => {
                return f.value != null
            })
            return enabledFilters;
        }).flat().map(f => ({ name: f.name, value: f.value }));
    }, [groupFilters]);

    useEffect(() => {
        const queryParams = new URLSearchParams();
        if (page) {
            queryParams.append('page', page.toString());
        }
        if (filters.length > 0) {
            filters.forEach(filter => {
                queryParams.append(filter.name, filter.value);
            });
        }
        if (search) {
            queryParams.append('search', search);
        }

        if (accountId) {
            queryParams.append('account_id',accountId.toString() || '');
        }
        else {
            queryParams.append('account_id',user?.id.toString() || '');
        }

        if (statusFilter) {
            queryParams.append('status', statusFilter || '');
        } else {
            queryParams.delete('status');
        }

        const queryParamsString = queryParams.toString();
        if (queryParamsString) {
            navigate(`?${queryParams.toString()}`);
        }

        fetchData();

    }, [page, search, filters, statusFilter, accountId, statusFilter]);

    async function fetchData() {
        try {
            setLoading(true);
            const res = await roadDemandesService.list(page, search, filters, accountId, statusFilter ? statusFilter : undefined);
            setDatapaginate(res);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
    
    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: RoadDemande) => {
            return {
                id: item.id,
                created_at: dateHelper.dateToDDMMYYYY(item.created_at),
                origin: item.getLabelOrigin(),
                destination: item.getLabelDestination(),
                origin_date_start: dateHelper.dateToDDMMYYYY(item.origin_date_start),
                destination_date_start: dateHelper.dateToDDMMYYYY(item.destination_date_start),
                unit: item.features?.unit ? item.features?.getUnitLabel() : '-',
                quantity: item.features?.quantity ? item.features?.quantity : '-',
                total_weight: item.features?.total_weight ? `${item.features?.total_weight} kg` : '-',
                status: <StatusRoadCotation status={item.status} />,
            }
        });
    }, [dataPaginate]);

    const accountOptions = useMemo(() => {
        if(!user?.client?.accounts) return [];

        return user?.client?.accounts.map(account => {
            return {
                label: account.name,
                value: account.id
            }
        })
    }, [user]);

    const handleSearch = useDebounce((term) => {
        setPage(1);
        setSearch(term)
    }, 500);

    async function onEdit(demandeId: number) {
            const detailCotation = await roadDemandesService.find(demandeId);
            setCotation(detailCotation);
            setShowModal(true);
    }
    
    return (
        <>
            <Header title="Cotations de transport route">
                <Button type="primary" onClick={() => setShowModal(true)}>
                    <Space size={6} onClick={() => setShowModal(true)}>
                        <Icon path="/icons/plus.svg" size={20} />
                        <Text target="Bold">Ajouter une cotation</Text>
                    </Space>
                </Button>
            </Header>
            <Layout className="container">
                <Flex vertical gap={16}>
                <Flex justify="space-between">
                    <Input
                        size="large"
                        placeholder="Rechercher..."
                        prefix={<Icon path="/icons/search-refraction.svg" size={20} />}
                        style={{ width: 320 }}
                        defaultValue={search ? search : undefined}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Space size={8}>                            
                        <Button
                            icon={<Icon path="./icons/check-done.svg"
                            size={20} />}
                            onClick={() => {
                                setStatusFilter(statusFilter !== StatusRoadDemande.STATUS_WAITING_ACCEPTATION ? StatusRoadDemande.STATUS_WAITING_ACCEPTATION : null);
                            }}
                            className={statusFilter == StatusRoadDemande.STATUS_WAITING_ACCEPTATION ? 'selected-button' : ''}
                        >
                            <Text target="Bold">Mes cotations à valider</Text>
                        </Button>

                        <Button
                            icon={<Icon path="./icons/check-done.svg"
                            size={20} />}
                            onClick={() => {
                                setStatusFilter(statusFilter !== StatusRoadDemande.PENDING_PRICE ? StatusRoadDemande.PENDING_PRICE : null);
                            }}
                            className={statusFilter == StatusRoadDemande.PENDING_PRICE ? 'selected-button' : ''}
                        >
                            <Text target="Bold">Tarification en cours</Text>
                        </Button>
                            
                        <Select
                            showSearch
                            allowClear
                            placeholder="Sélectionner un compte"
                            onSearch={() => {}}
                            filterOption={ (input: any, option: any) =>
                                option && option.label && option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={accountOptions}
                            style={{ width: 250 }}
                            defaultValue={searchParams.get('account_id') ? parseInt(searchParams.get('account_id') || "0") : user?.id}
                            onChange={(value) => {
                                setAccountId(value);
                            }}
                        />

                            <ButtonColumn columns={columns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} />
                            {/*<Button icon={<Icon path="./icons/filter-lines.svg" size={20} />} onClick={() => setShowFilters(true)} >
                            <Text target="Bold">Filtres ({nbFilters})</Text>
                        </Button>*/}
                    </Space>
                </Flex>
                <Table
                    rowKey={"id"}
                    loading={loading}
                    columns={renderColumns()}
                    dataSource={dataTable}
                    scroll={{ x: 1327, y: 1080 }}
                    showSorterTooltip={false}
                    onRow={(record) => { return { onClick: () => { onEdit(record.id) } } }}
                    pagination={{
                        current: page,
                        pageSize: dataPaginate?.per_page || 10,
                        total: dataPaginate?.total || 0,
                            onChange: (page) => {
                                setPage(page);
                            },
                    }}
                />
                </Flex>
            </Layout>
            {showModal && <PanelRoadCotation
                showModal={showModal}
                onClose={() => { setShowModal(false); setCotation(undefined); fetchData(); }}
                cotation={cotation}
            />}

            {showFilters && groupFilters &&
                <DrawerFilterRoadQuotation
                    groupFilters={groupFilters}
                    isOpen={showFilters}
                    onClose={(groups?: GroupFilter[]) => {
                        if (groups) {
                            setGroupFilters(groups)
                        } else {
                            setShowFilters(false)
                        }
                    }}
                />}
        </>
    );
}