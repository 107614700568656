import type { ColumnsType } from "antd/es/table";
import { RoadDemandeDataType } from "../models/RoadDemandeDataType";
import { RoadContractDataType } from "../models/RoadContractDataType";

const columns: ColumnsType<RoadContractDataType> = [
    {
        title: "Réf. client",
        dataIndex: "client_reference",
        key: "client_reference",
        sorter: false,
        width: 130
    },
    {
        title: "Statut",
        dataIndex: "status",
        key: "status",
        sorter: false,
        width: 180
    },
    {
        title: "Date / heure de chargement",
        dataIndex: "loading_date_end",
        key: "loading_date_end",
        sorter: false,
        width: 140
    },
    {
        title: "Origine Code pays",
        dataIndex: "loading_country",
        key: "loading_country",
        sorter: false,
        width: 100
    },
    {
        title: "Origine Code postal",
        dataIndex: "loading_postal_code",
        key: "loading_postal_code",
        sorter: false,
        width: 100
    },
    {
        title: "Origine Ville",
        dataIndex: "loading_city",
        key: "loading_city",
        sorter: false,
        width: 100
    },
    {
        title: "Destination Code pays",
        dataIndex: "delivery_country",
        key: "delivery_country",
        sorter: false,
        width: 100
    },
    {
        title: "Destination Code postal",
        dataIndex: "delivery_postal_code",
        key: "delivery_postal_code",
        sorter: false,
        width: 100
    },
    {
        title: "Destination Ville",
        dataIndex: "delivery_city",
        key: "delivery_city",
        sorter: false,
        width: 100
    },
    {
        title: "Quantité",
        dataIndex: "quantity",
        key: "quantity",
        sorter: false,
        width: 80
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: false,
        width: 80
    },
    {
        title: 'Prix de vente HT€ (hors surtaxe gasoil)',
        dataIndex: 'selling_price',
        key: 'selling_price',
        sorter: false,
        width: 160
    }
];

export { columns }