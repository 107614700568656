import { Flex } from "antd";
import User from "../../../../models/user";
import Avatar from "../../../ui/avatar/avatar";
import { Text } from "../../../ui/typo/Text";

interface IProps{
    user: User
}
export default function UserTag({ user }: IProps) {
    return (
        <Flex className="user-tag" gap={20} align="center">
            <Avatar placeholder={user.getLettersName()} size="small" />
            <Text target="Bold" style={{fontSize: 11}} >{user.getFullName() || '-'}</Text>
        </Flex>
    )
    
}