import axios, { AxiosResponse } from "axios";
import DataPaginate from "../models/utils/data-paginate";
import RoadDemande from "../models/RoadDemande";
import EstimateDistanceCO2 from "../models/estimate-distance-co2";

async function list(page: number = 1, search: string = '', filters: {name: string, value: any}[] = [], accountId?: number, status?: string) {
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    if (search) {
        queryParams.append('search', search);
    }
    if (filters.length > 0) {
        filters.forEach(filter => {
            queryParams.append(filter.name, filter.value);
        });
    }
    const params = {
        params: queryParams
    }

    if (accountId) {
        params.params.append('account_id', accountId.toString());
    }

    if (status) {
        params.params.append('status', status);
    }

    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes`, params).then(async (response: AxiosResponse) => {
        const dataPaginate = new DataPaginate(response.data);
        dataPaginate.setData(dataPaginate.data.map(item => new RoadDemande(item)));

        return dataPaginate;
    })
}

async function find(id: number) {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${id}`).then(async (response: AxiosResponse) => {
        return new RoadDemande(response.data);
    })
}

async function create(data: any) {
    return await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes`, data).then(async (response: AxiosResponse) => {
        return new RoadDemande(response.data);
    })
}

async function update(id: number, data: any) {
    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${id}`, data).then(async (response: AxiosResponse) => {
        return new RoadDemande(response.data);
    })
}

async function destroy(id: number) {
    return await axios.delete(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${id}`);
}

//TODO VERIFY
async function send(id: number) {
    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${id}/send`);
}

async function getEstimationCO2(id: number, distance?: number) {
    const params = distance ? `?distance=${distance}` : '';
    const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/transport/road/demandes/${id}/estimate-distance-co2${params}`);
    
    return new EstimateDistanceCO2(response.data);
}

async function uploadAttachment(id: number, file: any, type: 'client' | 'internal') {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${id}/attachments/${type}`, formData, 
        {
             headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );

    return response.data;
}

async function getAttachments(id: number, type: 'client' | 'internal') {
    const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${id}/attachments/${type}`);

    return response.data;
}

async function deleteAttachment(id: number, attachmentId: string, type: 'client' | 'internal') {
    return await axios.delete(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${id}/attachments/${type}/${attachmentId}`);
}

async function getEstimateDevis(id: number, data: any){
    return await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/app/transport/road/demandes/${id}/estimate-devis`, data).then(async (response: AxiosResponse) => {
        return response.data;
    })
}

export default {
    list,
    find,
    create,
    update,
    destroy,
    send,
    getEstimationCO2,
    uploadAttachment,
    getAttachments,
    deleteAttachment,
    getEstimateDevis
}