import { Drawer, Select } from "antd";
import { Button, DatePicker, Form, Space } from "antd";
import { Text } from "../../../ui/typo/Text";
import { ArrowLeftOutlined, CheckOutlined } from "@ant-design/icons";
import GroupFilter from "../../../../models/utils/group-filter";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FilterType } from "../../../../models/utils/filter";

interface IProps{
    isOpen: boolean;
    groupFilter?: GroupFilter;
    onClose: () => void;
    onAppliedFilters: (groupFilter: GroupFilter) => void;
}
export default function DrawerFilterDetail({ isOpen, groupFilter, onClose, onAppliedFilters }: IProps) {
    const [selectedGroupFilter, setSelectedGroupFilter] = useState<GroupFilter>();
    useEffect(() => {
        if (groupFilter) {
            // Crée une nouvelle instance de GroupFilter pour éviter toute mutation
            const clonedGroupFilter = new GroupFilter({ 
                label: groupFilter.label, 
                filters: groupFilter.filters.map(filter => ({ ...filter })), 
                formater: groupFilter.formater 
            });
            setSelectedGroupFilter(clonedGroupFilter);
        } else {
            setSelectedGroupFilter(undefined);
        }
    }, [groupFilter])

    function updateFilterDate(name: string, value: any) {
        const filter = selectedGroupFilter?.filters.find(f => f.name === name);
        if (filter) {
            filter.value = value;
            filter.labelValue = value ? value.format("DD/MM/YYYY") : "";
        }
    }

    function updateFilterSelect(name: string, value: any, list: {label: string, value: any}[]) {
        const filter = selectedGroupFilter?.filters.find(f => f.name === name);
        if (filter) {
            filter.value = value;
            filter.labelValue = list.find(l => l.value === value)?.label;
        }
    }

    function updateFilterDropdown(name: string, value: any, list: {label: string, value: any}[]) {
        const filter = selectedGroupFilter?.filters.find(f => f.name === name);
        if (filter) {
            filter.value = value;
            filter.labelValue = list.find(l => l.value === value)?.label;
        }
    }


    return (
        <Drawer
            title={<Text target="H1">Filtres</Text>}
            placement="right"
            width={680}
            open={isOpen}
            onClose={onClose}
            footer={
                <Space size={16} style={{ justifyContent: "flex-end", width: "100%" }}>
                <Button type="default" onClick={onClose}>
                    <Space size={6}>
                        <ArrowLeftOutlined />
                        <Text target="Bold">Annuler</Text>
                    </Space>
                </Button>
                    <Button type="primary" onClick={() => {
                        if (selectedGroupFilter) {
                            onAppliedFilters(selectedGroupFilter);
                        }
                    }}>
                    <Space size={6}>
                        <CheckOutlined />
                        <Text target="Bold">Appliquer</Text>
                    </Space>
                </Button>
                </Space>
            }
            >
            <Form layout="vertical">
                {groupFilter && <Space direction="vertical" size={16} style={{ width: "100%" }}>
                    <Text target="H2">{groupFilter?.label}</Text>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        {groupFilter?.filters.map((filter, index) => (
                            <Fragment key={index}>
                                <Form.Item key={index} label={filter.label}>
                                    {filter.type === FilterType.DATE && <DatePicker
                                        onChange={(value) => updateFilterDate(filter.name, value)}
                                        placeholder="-- / -- / ----"
                                        suffixIcon={false}
                                        format="DD/MM/YYYY"
                                        style={{ width: "100%" }}
                                        defaultValue={filter.value}
                                    />}

                                    {filter.type === FilterType.SELECT && <Select
                                        showSearch
                                        placeholder={''}
                                        options={filter.list} 
                                        onChange={(value) => updateFilterSelect(filter.name, value, filter.list ? filter.list : [])}
                                        defaultValue={filter.value}
                                        allowClear={true}
                                        onClear={() => updateFilterSelect(filter.name, undefined, filter.list ? filter.list : [])}
                                    />
                                    }
                                </Form.Item>
                            </Fragment>
                            
                        ))}
                        
                    </Space>
                </Space>}
            </Form>
        </Drawer>

    )
}