import { Drawer, Flex, Space, Steps } from "antd";
import { Text } from "../../ui/typo/Text";
import { useEffect, useMemo, useState } from "react";
import FormQuotationInformations from "./form-quotation-informations";
import RoadDemande from "../../../models/RoadDemande";
import devisService from "../../../services/devis.service";
import Devis from "../../../models/Devis";
import PreviewDevis from "./preview-devis";

interface IProps{
    showModal: boolean;
    onClose: (refresh?: boolean) => void;
    cotation?: RoadDemande;
    
}
export default function PanelRoadCotation({ showModal, onClose, cotation }: IProps) {
    const [current, setCurrent] = useState(cotation ? cotation.getDefaultTabDemand() : 0);
    const [currentCotation, setCurrentCotation] = useState<RoadDemande | undefined>(cotation);
    const [loading, setLoading] = useState(true);
    const [devis, setDevis] = useState<Devis>();

    async function fetchDevis(goNext?: boolean) {
        if (currentCotation) {
            try {
                setLoading(true);
                const devis = await devisService.get(currentCotation.id);
                setDevis(devis);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
                if (goNext) {
                    setCurrent(2);
                }
            }
        }
    }

    useEffect(() => {
        fetchDevis();
    }, [currentCotation])

    const canEditForms = useMemo(() => {
        if (devis && (devis.isPending() || devis.isAccepted())) {
            return false;
        }
        return true;
    }, [devis])

    return (
        <Drawer
            title={<Text target="H2">{cotation ? `Demande de transport n°${cotation.code}` : 'Nouvelle demande de transport'}</Text>}
            placement="right"
            width={720}
            open={showModal}
            closeIcon={false}
            onClose={() => onClose()}
            >
            {current == 0 && <>
                <FormQuotationInformations
                    cotation={currentCotation}
                    devis={devis}
                    canEdit={canEditForms}
                    onSaved={(cotation) => setCurrentCotation(cotation)}
                    onClose={() => onClose()}
                />
            </>}
            {current == 1 && devis && currentCotation && <PreviewDevis cotation={currentCotation} devis={devis} refreshDevis={() => fetchDevis()} />}
        </Drawer>
    );
}