import axios, { AxiosResponse } from "axios";

async function getAnnexes() {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/pricing/annexes`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}

async function downloadAnnexe(code: string) {
    const res = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/pricing/annexes/${code}/download`, {
        responseType: 'blob'
    });

    return res.data
}

export default {
    getAnnexes,
    downloadAnnexe,
}