import { Button, Col, Flex, Form, Input, Row, Space } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import FormSelectSingle from "./form-select-single";
import { useEffect, useMemo, useRef } from "react";
import FormDatePicker from "./form-datepicker";
import FormTimePicker from "./form-timepicker";
import FormAddressAutoComplete from "./form-address-auto-complete";
import { useWatch } from "react-hook-form";

interface IProps{
    fieldName: string;
    control: any;
    fields: any[];
    errors: any
    onRemove: (index: number) => void;
    canEdit: boolean;
    setValue: any;
}
export default function SubformStopPoint({ fieldName, control, fields, errors, onRemove, canEdit, setValue }: IProps) {

    const typeOptions = useMemo(() => [
        { label: 'Chargement', value: 'loading' },
        { label: 'Déchargement', value: 'unloading' },
    ], [])

    const stopPoints = useWatch({
        control,
        name: fieldName
    })

    const prevStopPointsRef = useRef([]);
    useEffect(() => {
        const prevStopPoints = prevStopPointsRef.current;
        
        if (stopPoints && prevStopPoints) {
            stopPoints.forEach((stopPoint: any, index: number) => {
                const prevStopPoint = prevStopPoints[index];
                if (prevStopPoint) {
                    Object.keys(stopPoint).forEach((key) => {
                        if (stopPoint[key] !== prevStopPoint[key] && key == 'date_start_date') {
                            setValue(`${fieldName}.${index}.date_end_date`, stopPoint[key]);
                        }
                    });
                }
            });
        }
        
        // Update the previous values
        prevStopPointsRef.current = stopPoints;
    }, [stopPoints]);

    return (
        <Space direction="vertical" size={16} style={{ width: "100%" }}>
            {fields.map((field, index) => (
                <div key={`field-${field.id}`} style={{width: "100%"}}>
                    <Flex gap={16} style={{ width: "100%" }} >
                        <FormAddressAutoComplete
                            label="Arrêt supplémentaire"
                            required
                            name={`${fieldName}.${index}.address`}
                            placeholder="Entrer l'adresse"
                            control={control}
                            disabled={!canEdit}
                            style={{ width: 400}}
                        />
                            
                        <FormSelectSingle
                            style={{flexGrow: 2}}
                            label="Type d'arrêt"
                            placeholder="Choisir le type"
                            name={`${fieldName}.${index}.type`}
                            defaultValue={field?.value?.type}
                            control={control}
                            options={typeOptions}
                            onSearch={() => null}
                            required
                            error={errors?.stop_points?.[index]?.type}
                            disabled={!canEdit}
                        />
                        <div>
                            <Form.Item label=" ">
                                <Button
                                    icon={<MinusOutlined />}
                                    onClick={() => onRemove(index)}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </div>
                        
                    </Flex>

                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <FormDatePicker
                                label="Date de début"
                                name={`${fieldName}.${index}.date_start_date`}
                                control={control}
                                placeholder="-- / -- / ----"
                                error={errors?.stop_points?.[index]?.date_start_date}
                                disabled={!canEdit}
                            />
                        </Col>
                        <Col span={6}>
                            <FormTimePicker
                                label="Heure de début"
                                name={`${fieldName}.${index}.date_start_hours`}
                                control={control}
                                placeholder="-- : --"
                                error={errors?.stop_points?.[index]?.date_start_hours}
                                disabled={!canEdit}
                            />
                        </Col>

                        <Col span={6}>
                            <FormDatePicker
                                label="Date de fin"
                                name={`${fieldName}.${index}.date_end_date`}
                                control={control}
                                placeholder="-- / -- / ----"
                                error={errors?.stop_points?.[index]?.date_end_date}
                                disabled={!canEdit}
                            />
                        </Col>
                        <Col span={6}>
                            <FormTimePicker
                                label="Heure de fin"
                                name={`${fieldName}.${index}.date_end_hours`}
                                control={control}
                                placeholder="-- : --"
                                error={errors?.stop_points?.[index]?.date_end_hours}
                                disabled={!canEdit}
                            />
                        </Col>
                    </Row>
                </div>
            ))}
        </Space>
    )
}