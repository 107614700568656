import { Button, Dropdown, Flex, MenuProps } from "antd";
import { Icon } from "../typo/Icon";
import { Text } from "../typo/Text";
import { ColumnsType } from "antd/es/table";
import { useMemo, useRef, useState } from "react";

interface IProps{
    columns: ColumnsType<any>;
    visibleColumns: string[];
    setVisibleColumns: (columns: string[]) => void;
}
export default function ButtonColumn({ columns, visibleColumns, setVisibleColumns }: IProps) {
    const [visible, setVisible] = useState(false);
    
    const items: MenuProps['items'] = useMemo(() => {
        return columns.map((column: any) => {
            const isSelected = visibleColumns.includes(column.key);

            return {
                key: column.key,
                label: (
                    <Flex gap={20} justify="space-between">
                        <Text target={!isSelected ? 'Regular' : 'Bold'}>{column.title}</Text>
                        {isSelected && <Icon path="./icons/check-black.svg" size={20} />}
                    </Flex>
                ),
                onClick: () => handleSelect(column.key),
            }
        });
    }, [columns, visibleColumns]);

    function handleSelect( key: string) {
        const newVisibleColumns = visibleColumns.includes(key) ? visibleColumns.filter((k) => k !== key) : [...visibleColumns, key];
        setVisibleColumns(newVisibleColumns);
    }

    return (
        <Dropdown menu={{
            items,
            onMouseLeave: () => setVisible(false),
        }} placement="bottomLeft" open={visible}>
            <Button onClick={() => setVisible(!visible)} icon={<Icon path="./icons/columns.svg" size={20}  />}>
                <Text target="Bold">Colonnes ({visibleColumns.length})</Text>
            </Button>
        </Dropdown>
    )
}