import { Flex, Image, Layout, Menu } from "antd";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuConf } from "../../configs/MenuConf";
import { useAppSelector } from "../../store/hooks";
import authService from "../../services/auth.service";
import Avatar from "../ui/avatar/avatar";
import { Icon } from "../ui/typo/Icon";

export default function Sider() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const {value : user} = useAppSelector(state => state.user);


  const handleMenuClick = ({ key } : {key: string}) => {
    if (key) {
      navigate(key);
    }
  };

  const getDefaultSelectedKeys = () => {
    const keys = pathname.split('/');

    return keys.length > 1 ? [keys[1] ? keys[1] : '/'] : [];
  }
  

  return (
    <Layout.Sider theme="dark" width={316}>
      <Flex vertical gap={24}  style={{height: '100%'}}>
        <Image src="/calque_logo.svg" width={221} height={74} preview={false} />
        <Menu
          theme="dark"
          items={MenuConf}
          inlineIndent={12}
          defaultSelectedKeys={getDefaultSelectedKeys()}
          defaultOpenKeys={["dossiers-route", "dossiers-maritime"]}
          mode="inline"
          onClick={handleMenuClick}
          style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}
        />
        
        {user && <>
          <div>
    
            <Link className="flex row align-item-center" to="https://genysia.notion.site/Tutoriel-d-utilisation-MyDTS-9f9f0a96d4934a0a8d1875c9bb851cda?pvs=74" style={{color: 'white', fontSize: 16, fontWeight: 600, textDecoration: 'none', gap: 10, marginLeft: 10, marginBottom: 20}}>
            <Icon path="/icons/info-circle-white.svg" size={24} />
              Guide d'utilisation - Aide
            </Link>
      
            <div className="flex row align-item-center" style={{ marginLeft: 10, marginBottom: 10 }}>
              <Avatar placeholder={user.getLettersName()} size="normal" />
              <div className="flex column" style={{marginLeft: 8, gap: 4}}>
                <div style={{color: 'white', fontSize: 14, fontWeight: 600}}>{user.name}</div>
                <div style={{color: '#D0D5DD', fontSize: 14, fontWeight: 600, cursor: 'pointer' }} onClick={() => authService.logout(navigate)}>
                  Se déconnecter
                </div>
              </div>
            </div>
          </div>
        </>}
      </Flex>
    </Layout.Sider>
  );
}
