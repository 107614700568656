import axios, { AxiosResponse } from "axios";
import DataPaginate from "../models/utils/data-paginate";
import Contract from "../models/contract";

async function list(page: number = 1, search: string = '', filters: {name: string, value: any}[] = [], accountId?: number) {
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    if (search) {
        queryParams.append('search', search);
    }
    if (filters.length > 0) {
        filters.forEach(filter => {
            queryParams.append(filter.name, filter.value);
        });
    }
    const params = {
        params: queryParams
    }

    if (accountId) {
        params.params.append('account_id', accountId.toString());
    }

    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/contracts`, params).then(async (response: AxiosResponse) => {
        const dataPaginate = new DataPaginate(response.data);
        dataPaginate.setData(dataPaginate.data.map(item => new Contract(item)));

        return dataPaginate;
    })
}

async function find(id: number) {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/contracts/${id}`).then(async (response: AxiosResponse) => {
        return new Contract(response.data);
    })
}

export default {
    list,
    find
}