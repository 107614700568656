import { Flex, Input, Layout, Select, Space, Table } from "antd";
import Header from "../../components/layout/Header";
import { Icon } from "../../components/ui/typo/Icon";
import ButtonColumn from "../../components/ui/buttons/button-column";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../models/utils/data-paginate";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import useTable from "../../utils/hooks/useTable";
import { columns } from "../../configs/RoadContractsTableColumns";
import roadContractsService from "../../services/road-contracts.service";
import dateHelper from "../../utils/date.helper";
import Contract from "../../models/contract";
import useDebounce from "../../utils/hooks/useDebounce";
import StatusContractComponent from "../../components/core/road-folder/status-contract";


export default function ListContractRoadQuotationPage() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [page, setPage] = useState<number>(searchParams.has('page') ? parseInt(searchParams.get('page') || '1') : 1);
    const [search, setSearch] = useState<string>(searchParams.get('search') || '');
    const { renderColumns, visibleColumns, setVisibleColumns } = useTable(columns)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {value : user} = useAppSelector(state => state.user);
    const [accountId, setAccountId] = useState<number | undefined>(searchParams.get('account_id') ? parseInt(searchParams.get('account_id') || "0") : user?.id);

    useEffect(() => {
        const queryParams = new URLSearchParams();
        if (page) {
            queryParams.append('page', page.toString());
        }
        if (search) {
            queryParams.append('search', search);
        }

        if (accountId) {
            queryParams.append('account_id', accountId.toString() || '');
        } else {
            queryParams.append('account_id', user?.id.toString() || '');
        }

        const queryParamsString = queryParams.toString();
        if (queryParamsString) {
            navigate(`?${queryParams.toString()}`);
        }

        fetchData();

    }, [page, search, undefined, accountId, user]);

    async function fetchData() {
        try {
            setLoading(true);
            const res = await roadContractsService.list(page, search, undefined, accountId ? accountId : undefined);
            setDatapaginate(res);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
    
    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: Contract) => {
            return {
                id: item.id,
                key: item.id,
                client_reference: item.client_reference ? item.client_reference : '-',
                status: item.status ? <StatusContractComponent contract={item} /> : '-',
                loading_date_end: item.loading_date_end ? dateHelper.dateToDDMMYYYYHHMM(item.loading_date_end) : '-',
                loading_country: item.loading_country ? item.loading_country : '-',
                loading_postal_code: item.loading_postal_code ? item.loading_postal_code : '-',
                loading_city: item.loading_city ? item.loading_city : '-',
                delivery_country: item.delivery_country ? item.delivery_country : '-',
                delivery_postal_code: item.delivery_postal_code ? item.delivery_postal_code : '-',
                delivery_city: item.delivery_city ? item.delivery_city : '-',
                quantity: item.quantity ? item.quantity : '-',
                type: item.type ? item.type : '-',
                selling_price: item.selling_price ? `${item.selling_price} € HT` : '-',
            }
        });
    }, [dataPaginate]);

    const accountOptions = useMemo(() => {
        if(!user?.client?.accounts) return [];

        return user?.client?.accounts.map(account => {
            return {
                label: account.name,
                value: account.id
            }
        })
    }, [user]);

    const handleSearch = useDebounce((term) => {
        setPage(1);
        setSearch(term)
    }, 500);
    return (
         <>
            <Header title="Contrats de transport route">
            </Header>
            <Layout className="container">
                <Flex vertical gap={16}>
                    <Flex justify="space-between">
                        <Input
                            size="large"
                            placeholder="Rechercher..."
                            prefix={<Icon path="/icons/search-refraction.svg" size={20} />}
                            style={{ width: 320 }}
                            defaultValue={search ? search : undefined}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <Space size={8}>
  
                            <Select
                                showSearch
                                allowClear
                                placeholder="Sélectionner un compte"
                                onSearch={() => {}}
                                filterOption={ (input: any, option: any) =>
                                    option && option.label && option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={accountOptions}
                                style={{ width: 250 }}
                                defaultValue={searchParams.get('account_id') ? parseInt(searchParams.get('account_id') || "0") : user?.id}
                                onChange={(value) => {
                                    setAccountId(value);
                                }}
                                
                            />
                            <ButtonColumn columns={columns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} />
                                
                        </Space>
                    </Flex>
                    <Table
                        rowKey={"id"}
                        loading={loading}
                        columns={renderColumns()}
                        dataSource={dataTable}
                        scroll={{ x: 1327, y: 1080 }}
                        showSorterTooltip={false}
                        onRow={(record) => { return { onClick: () => { navigate(`/road-contracts/${record.id}`) } } }}
                        pagination={{
                            current: page,
                            pageSize: dataPaginate?.per_page || 10,
                            total: dataPaginate?.total || 0,
                            onChange: (page) => {
                                setPage(page);
                            },
                        }}
                    />
                </Flex>
            </Layout>
        </>
    )
}