import { Button, Card, Flex, Space } from "antd";
import Devis from "../../../models/Devis";
import PreviewPDF from "../commons/preview-pdf/preview-pdf";
import { Text } from "../../ui/typo/Text";
import { DownloadOutlined, SendOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";
import devisService from "../../../services/devis.service";
import RoadDemande from "../../../models/RoadDemande";
import { useEffect, useState } from "react";
import pricingService from "../../../services/pricing.service";
import { Icon } from "../../ui/typo/Icon";

interface IProps{
    cotation: RoadDemande;
    devis: Devis;
    refreshDevis: () => void;
}
export default function PreviewDevis({ cotation, devis, refreshDevis }: IProps) {
    const dispatch = useAppDispatch();
    const [annexes, setAnnexes] = useState<any>(
        [
            {
                code: 'general_sales_conditions',
                label: 'Conditions générales de vente',
                tmpFile: null,
                existingFile: null,
                path: null,
                created_at: null,
                update_at: null
            },
            {
                code: 'terms_of_payment',
                label: 'Conditions de réglement',
                tmpFile: null,
                existingFile: null,
                path: null, 
                created_at: null,
                updated_at: null
            }
        ]
    );

    useEffect(() => {
        async function fetchData() {
                try {
                    const res = await pricingService.getAnnexes();

                    console.log(
                         annexes.map((annexe: any) => {
                            const found = res.find((r: any) => r.code === annexe.code);
                                return {
                                    ...annexe,
                                    existingFile: found ? found.file : null,
                                    path: found ? found.path : null,
                                    created_at: found ? found.created_at : null,
                                    updated_at: found ? found.updated_at : null
                                }
                        })
                    )


                    setAnnexes(
                        annexes.map((annexe: any) => {
                            const found = res.find((r: any) => r.code === annexe.code);
                                return {
                                    ...annexe,
                                    existingFile: found ? found.file : null,
                                    path: found ? found.path : null,
                                    created_at: found ? found.created_at : null,
                                    updated_at: found ? found.updated_at : null
                                }
                        })
                    );
                } catch (e) {
                    console.log(e)
                } 
        }
        fetchData();

    }, [])

    async function onViewAnnexe(annexe: any) {
        try {
            if (annexe) {
                const blob: any = await pricingService.downloadAnnexe(annexe.code);
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
                /*const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                const parts = annexe.path.split("/");
                const filename = parts[parts.length - 1];
                link.download = `${filename}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);*/
            }
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier :", error);
            dispatch(addToast(new Toast(`Une erreur est survenue pendant le téléchargement des ${annexe.label.toLowerCase} !`, 'error')))
        }
    }

    
    
    const downloadPdf = () => {
        if (devis.public_url) {
            const url = devis.public_url;
            const filename = `${devis.code}.pdf`;
            // Download in new tab
            /*const link = document.createElement('a');
            link.style.display = 'none';
            document.body.appendChild(link);

            link.href = devis.public_url;

            link.setAttribute('download', `${devis.code}.pdf`);
            link.setAttribute('target', '_blank');

            link.click();

            document.body.removeChild(link);*/

            // Download manually
            fetch(url, {
                headers: {
                'Content-Type': 'application/pdf'
                },
                method: 'GET'
            })
            .then(response => response.blob())
            .then(blob => {
                // Créer un objet URL pour le blob
                const url = window.URL.createObjectURL(blob);

                // Créer un élément <a> invisible
                const link = document.createElement('a');
                link.style.display = 'none';
                document.body.appendChild(link);

                // Définir l'URL du fichier à télécharger
                link.href = url;

                // Définir l'attribut download pour forcer le téléchargement
                link.setAttribute('download', filename);

                // Simuler un clic sur le lien pour démarrer le téléchargement
                link.click();

                dispatch(addToast(new Toast("Le téléchargement a débuté...", "success")));

                // Nettoyer après le téléchargement
                document.body.removeChild(link);
            })
                .catch(error => {
                    dispatch(addToast(new Toast("Le téléchargement a échoué !", "error")));
                    console.error('Erreur lors du téléchargement du fichier', error)
                });
        }
        
    };

    async function onAccept() {
        try {
            await devisService.accept(cotation.id);
            dispatch(addToast(new Toast("Le devis a été accepté", "success")));
            refreshDevis();
        } catch (e) {
            dispatch(addToast(new Toast("Le devis n'a pas été accepté !", "error")));
            console.log(e);
        }
    }

    async function onDecline() {
        try {
            await devisService.refuse(cotation.id);
            dispatch(addToast(new Toast("Le devis a été refusé", "success")));
            refreshDevis();
        } catch (e) {
            dispatch(addToast(new Toast("Le devis n'a pas été refusé !", "error")));
            console.log(e);
        }
    }
    
    return <>
        <Flex justify="space-between" style={{ flexDirection: "column", height: '100%' }}>
            
            <PreviewPDF pdfUrl={devis?.public_url || ''} />

            <div className="ant-drawer-footer">
                <Space
                    direction="horizontal"
                    size={16}
                    style={{ width: "100%", justifyContent: 'space-between' }}
                >
                    <Space
                        size={4}
                        direction="vertical"
                    >
                        {annexes.filter((annexe: any) => annexe.path != null ).map( (annexe:any) => 
                            <div className="flex" style={{gap: 5, cursor: 'pointer'}} onClick={() => onViewAnnexe(annexe)}>
                                <label style={{ color: '#242C43', cursor: 'pointer', fontWeight: 600 }}>{annexe.label}</label>
                                <Icon path="/icons/link-external.svg" size={20} />
                            </div>
                        )}
                    </Space>
                     <Space
                        size={16}
                    >
                        {devis.public_url && <Button type="default" onClick={() => downloadPdf()}>
                            <Space size={6}>
                                <DownloadOutlined />
                                <Text target="Bold">Télécharger</Text>
                            </Space>
                        </Button>}

                        {devis.isRefused() &&
                            <Card style={{borderColor: '#990717', marginBottom: 10}}>
                                <Text target="Regular" style={{color:'#990717'}} >Le devis a été refusé !</Text>
                            </Card>
                        }
                        {devis.isAccepted() &&
                            <Card style={{borderColor: '#079455', marginBottom: 10}}>
                                <Text target="Regular" style={{color:'#079455'}} >Le devis a été accepté !</Text>
                            </Card>
                        }
                        {devis.isPending() && <>
                            <Button style={{backgroundColor: '#D92D20'}} type="primary" onClick={() => onDecline()}>
                                <Space size={6}>
                                    <CloseOutlined />
                                    <Text target="Bold">Refuser</Text>
                                </Space>
                            </Button>
                            <Button style={{backgroundColor: '#079455'}} type="primary" onClick={() => onAccept()}>
                                <Space size={6}>
                                    <CheckOutlined />
                                    <Text target="Bold">Valider</Text>
                                </Space>
                            </Button>
                        </>}
                    
                    </Space>
                </Space>
            </div>
        </Flex>

        

    </>
}