import Account from "./account";
import Agencie from "./agencie";
import User from "./user";

export default class Customer{
    id: number;
    code: string;
    name: string;
    sector: string;
    sector2: string;
    contact: string;
    email: string;
    tel: string;
    fax: string;
    gsm?: string;
    address1: string;
    address2: string;
    address3: string;
    address4?: string;
    postalCode: string;
    city: string;
    country: string;
    latitude?: number;
    longitude?: number;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
    agency?: Agencie;
    assigned_user?: User;
    accounts: Account[];

    constructor(data: any) {
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.sector = data.sector;
        this.sector2 = data.sector2;
        this.contact = data.contact;
        this.email = data.email;
        this.tel = data.tel;
        this.fax = data.fax;
        this.gsm = data.gsm;
        this.address1 = data.address1;
        this.address2 = data.address2;
        this.address3 = data.address3;
        this.address4 = data.address4;
        this.postalCode = data.postalCode;
        this.city = data.city;
        this.country = data.country;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        if(data.deleted_at)
            this.deleted_at = new Date(data.deleted_at);
        if (data.agency)
            this.agency = new Agencie(data.agency);
        if (data.assigned_user)
            this.assigned_user = new User(data.assigned_user);
        this.accounts = data.accounts ? data.accounts.map((account: any) => new Account(account)) : [];
    }
}