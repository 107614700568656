export enum StatusRoadDemande {
    NEW = "new",
    DRAFT="draft",
    CANCELLED = "cancelled",
    PENDING_PRICE = 'pending_price',
    STATUS_WAITING_ACCEPTATION = 'waiting_acceptation',
    STATUS_ACCEPTED = 'accepted',
    STATUS_REFUSED = 'refused',
}

export const statusRoadDemandeList = [
    { value: StatusRoadDemande.DRAFT, label: "Brouillon" },
    { value: StatusRoadDemande.NEW, label: "Demande envoyée" },
    { value: StatusRoadDemande.CANCELLED, label: "Annulé" },
    { value: StatusRoadDemande.PENDING_PRICE, label: "En attente cotation" },
    { value: StatusRoadDemande.STATUS_WAITING_ACCEPTATION, label: "Cotation à valider" },
    { value: StatusRoadDemande.STATUS_ACCEPTED, label: "Accepté" },
    { value: StatusRoadDemande.STATUS_REFUSED, label: "Décliné" },
]