import { Layout } from "antd";
import Sider from "../layout/Sider";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import authService from "../../services/auth.service";
import { useAppDispatch } from "../../store/hooks";
import { setUser } from "../../store/user/reducer";
import NotificationList from "../ui/notifications-list/notification-list";


export default function BaseTemplate() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        async function fetchUser() {
            await authService.updateAxiosInterceptors(navigate);
            await authService.updateAxiosAuthorization();
            const user = await authService.me();
            dispatch(setUser(user))
            setLoading(false);
        }

        fetchUser();
    }, [])

    return !loading ? <Layout>
        <Sider />
        <Layout.Content>
            <Layout>
                <Outlet />
            </Layout>   
        </Layout.Content>
        <NotificationList />
    </Layout> : <></>
        
    
}