import { useState } from "react";
import { Space, Flex, Card, Form, Button } from "antd";
import { Text } from "../../components/ui/typo/Text";
import VerificationInput from "../../components/form/VerificationInput";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import authService from "../../services/auth.service";

export default function ValidationCodePage() {
  const { state } = useLocation();
  const { token_login } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [buttonStatus, setButtonStatus] = useState(false);

  const [validationCode, setValidationCode] = useState("");
  const [isInvalidUser, setIsInvalidUser] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [previousUrl, setPreviousUrl] = useState<string>(searchParams.get('previous_url') || '');


  const handleCode = (code: string) => {
    // Received the code from code input
    setValidationCode(code);
    setButtonStatus(true);
    setIsLoading(true);
  };

  const handleSubmit = async () => {
    setIsInvalidUser(false);
    setIsLoading(true);
    setIsReset(true);
    try {
      await authService.login({ token_login, password: validationCode })
      if (previousUrl) {
          window.location.href = previousUrl;
      } else {
        navigate("/");
      }
    } catch (e) {
      console.log('fail', e)
      setIsInvalidUser(true);
      setIsLoading(false);
      setIsReset(false);
      setValidationCode("");
    }

  };

  return (
    <div className="validation-code-page">
      <div className="login-form-wrapper">
        <Flex vertical gap={48}>
          <img src="./img/login-logo.svg" alt="Logo" />
          <Space direction="vertical" size={32} style={{ width: "100%" }}>
            <Space
              direction="vertical"
              size={12}
              style={{
                width: "100%",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Text target="LoginTitle">Code de validation</Text>
              <Text target="Regular-Invert-Secondary">
                Un code de validation vous a été envoyé par email, veuillez le
                saisir ci-dessous
              </Text>
            </Space>
            <Form name="login">
              <Space direction="vertical" size={24} style={{ width: "100%" }}>
                <VerificationInput
                  isLoading={isLoading}
                  reset={isReset}
                  callback={handleCode}
                />
                <Button
                  type="primary"
                  disabled={!buttonStatus}
                  style={{ width: "100%" }}
                  onClick={handleSubmit}
                >
                  <Text target="H3">Se connecter</Text>
                </Button>
                <Button type="text" style={{ width: "100%" }}>
                  <Text target="Bold" style={{ color: "#ffffff" }}>
                    Retour
                  </Text>
                </Button>
                {isInvalidUser && <Space direction="vertical" style={{alignItems: "center", width: "100%"}}>
                    <Text target="Bold" className="error">Le code est invalide ou a expiré !</Text>
                  </Space>}
              </Space>
            </Form>
          </Space>
        </Flex>
      </div>
    </div>
  );
}
