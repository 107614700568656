import axios, { AxiosResponse } from "axios";
import Devis from "../models/Devis";

async function get(demandeId: number) {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${demandeId}/devis`).then(async (response: AxiosResponse) => {
        if (Object.keys(response.data).length !== 0) {
            return new Devis(response.data);
        }
        throw new Error('No devis');
    })
}

async function accept(demandeId: number) {
    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${demandeId}/devis/accept`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}

async function refuse(demandeId: number) {
    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/client/transport/road/demandes/${demandeId}/devis/refuse`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}
export default {
    get,
    accept,
    refuse
}