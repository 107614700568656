import { Drawer } from "antd";
import { useEffect, useState } from "react";
import { Button, List, Space, Flex } from "antd";
import { CloseOutlined, RightOutlined, UndoOutlined } from "@ant-design/icons";
import { Text } from "../../../ui/typo/Text";
import DrawerFilterDetailRoadQuotation from "./drawer-filter-detail";
import GroupFilter from "../../../../models/utils/group-filter";
import { FilterType } from "../../../../models/utils/filter";
import dayjs from 'dayjs';

interface IProps{
    isOpen: boolean;
    onClose: (groupsFilters?: GroupFilter[]) => void;
    groupFilters: GroupFilter[];
    title?: string;
}
export default function DrawerFilter({isOpen, onClose, groupFilters, title = "Filtres"}: IProps) {
    const [selectedGroupFilter, setSelectedGroupFilter] = useState<GroupFilter>();

    useEffect(() => {
        async function setData() {
            const updatedGroupFilters = await Promise.all(groupFilters.map(async g => {
                const filters = await Promise.all(g.filters.map(async f => {
                    if (f.value && !f.labelValue) {
                        if (f.type === FilterType.SELECT) {
                            f.labelValue = f.list?.find(l => l.value === f.value)?.label;
                        } else if (f.type === FilterType.DATE) {
                            f.labelValue = dayjs(f.value).format('DD/MM/YYYY');
                        } 
                    }
                    return f;
                }));
                g.filters = filters;
                return g;
            }));

            onClose(updatedGroupFilters);
        }
        setData();
    }, [])


    function onResetFilters() {
        onClose(groupFilters.map(g => {
            const filters =  g.filters.map(f => {
                f.value = undefined;
                f.labelValue = undefined;
                return f;
            })
            g.filters = filters;

            return g;
        }));
    }

    return (
        <>
            <Drawer
                title={<Text target="H1">{title}</Text>}
                placement="right"
                width={720}
                open={isOpen}
                onClose={() => onClose()}
                footer={
                <Space
                    size={16}
                    style={{ justifyContent: "flex-end", width: "100%" }}
                >
                    <Button
                        type="default"
                        style={{ marginLeft: "auto" }}
                        onClick={() => onResetFilters()}
                        >
                        <Space size={6}>
                            <UndoOutlined />
                            <Text target="Bold">Réinitialiser les filtres</Text>
                        </Space>
                    </Button>
                    <Button
                        type="default"
                        style={{ marginLeft: "auto" }}
                        onClick={() => onClose()}
                        >
                        <Space size={6}>
                            <CloseOutlined />
                            <Text target="Bold">Fermer</Text>
                        </Space>
                    </Button>
                </Space>
                }
            >
                

                <List className="filters-list">
                    {groupFilters.map((groupFilter, index) => (
                        <List.Item
                            key={index}
                            onClick={() => setSelectedGroupFilter(groupFilter)}
                            style={{ cursor: "pointer", flexDirection: "column", alignItems: "flex-start"}}
                        >
                            <Flex justify="space-between" style={{ width: "100%" }}>
                                <Text target="H2">{groupFilter.label}</Text>
                                <RightOutlined />
                            </Flex>
                            <Text target='Regular'>{groupFilter.formater()}</Text>
                        </List.Item>
                    ))}
                </List>
            </Drawer>
            <DrawerFilterDetailRoadQuotation
                isOpen={selectedGroupFilter !== undefined}
                groupFilter={selectedGroupFilter}
                onClose={() => setSelectedGroupFilter(undefined)}
                onAppliedFilters={(groupFilter) => {
                    setSelectedGroupFilter(undefined);
                    onClose(groupFilters.map(g => {
                        if (g.label === groupFilter.label) {
                            return groupFilter;
                        }
                        return g;
                    }));
                }}
            />
        </>
    )

}