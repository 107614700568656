import { Icon } from "../components/ui/typo/Icon";
import type { MenuProps } from "antd";
import { ERole } from "../models/user";

type MenuItem = Required<MenuProps>["items"][number];
type MenuItemWithPermission = MenuItem & {
  permission?: ERole;
};

export const MenuConf: MenuItemWithPermission[] = [
  {
    key: "dossiers-route",
    label: "Dossiers route",
    icon: <Icon path="/icons/truck-white.svg" size={24} />,
    permission: ERole.ROLE_ROAD_TRANSPORTS,
    children: [
      {
        key: "road-quotations?add=true",
        label: <div className="flex row align-item-center justify-content-between">Faire une demande <Icon path="./icons/plus-circle.svg" size={20} /></div>,
      },
      {
        key: "road-quotations",
        label: "Cotations de transport",
      },
      {
        key: "road-contracts",
        label: "Contrats de transport",
      },
    ],
  },
  {
    key: "dossiers-maritime",
    label: "Dossiers maritime",
    disabled: true,
    icon: <Icon path="/icons/waves-white.svg" size={24} />,
    permission: ERole.ROLE_SEA_TRANSPORTS,
    children: [
      {
        key: "sea-quotations?add=true",
        label: <div className="flex row align-item-center justify-content-between">Faire une demande <Icon path="./icons/plus-circle.svg" size={20} /></div>,
      },
      {
        key: "sea-quotations",
        label: "Cotations de transport",
      },
      {
        key: "sea-contracts",
        label: "Contrats de transport",
      },
  
    ],
  },
  {
    key: "invoices",
    label: "Factures",
    icon: <Icon path="/icons/files.svg" size={24} />,
    disabled: true
  },
  {
    key: "pricing-grid",
    label: "Grilles tarifaires",
    icon: <Icon path="/icons/grid-pricing.svg" size={24} />,
    disabled: true
  },
];
