import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Account from "../../models/account";

export interface IUserState {
  value: Account | undefined;
}

const initialState: IUserState = {
  value: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: IUserState, action: PayloadAction<Account | undefined>) => {
      state.value = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
