import type { ColumnsType } from "antd/es/table";
import { RoadDemandeDataType } from "../models/RoadDemandeDataType";

const columns: ColumnsType<RoadDemandeDataType> = [
    {
        title: "Date de la demande",
        dataIndex: "created_at",
        key: "created_at",
        sorter: false,
    },
        {
        title: "Origine",
        dataIndex: "origin",
        key: "origin",
        sorter: false,
    },
    {
        title:"Destination",
        dataIndex: "destination",
        key: "destination",
        sorter: false,
    },
    {
        title: "Date de chargement",
        dataIndex: "origin_date_start",
        key: "origin_date_start",
        sorter: false,
    },
    {
        title: "Date de livraison",
        dataIndex: "destination_date_start",
        key: "destination_date_start",
        sorter: false,
    },
    {
        title: "Unité",
        dataIndex: "unit",
        key: "unit",
        sorter: false,
    },
    {
        title: "Nombre",
        dataIndex: "quantity",
        key: "quantity",
        sorter: false,
    },
    {
        title: "Poids",
        dataIndex: "total_weight",
        key: "total_weight",
        sorter: false,
    },
    {
        title: "Statut",
        dataIndex: "status",
        key: "status",
        sorter: false,
    }
];

export { columns }