import axios, { AxiosResponse } from "axios"
import Customer from "../models/customer";
import Account from "../models/account";

async function generateToken(data: any) {
    return await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/client/auth/token-login`, data).then( async (response: AxiosResponse) => {
        return response.data;
    })
}

async function login(data: any) {
    return await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/client/auth/login`, data).then( async (response: AxiosResponse) => {
        localStorage.setItem("auth", JSON.stringify(response.data));
        return response.data;
    })
}

async function me() {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/auth/me`).then(async (response: AxiosResponse) => {
        return new Account(response.data);
    })
}

async function updateAxiosAuthorization() {
    await axios.interceptors.request.use((config: any) => {
      config.headers.Authorization = "Bearer " + getAuthToken();
  
      return config;
    });
  }
  
async function updateAxiosInterceptors(navigate: any) {
    await axios.interceptors.response.use(undefined, (error: any) => {
        const { status } = error.response;
        if (status === 401) {
            logout(navigate);
        }
        return Promise.reject(error);
    });
}

async function logout(navigate: any) {
    const currentUrl = window.location.href;
    window.localStorage.removeItem("auth");
    navigate(`/login?previous_url=${currentUrl}`);
}

function getAuthToken() {
    let auth = localStorage.getItem("auth");

    return auth ? JSON.parse(auth).access_token : null;
}

export default {
    generateToken,
    login,
    me,
    updateAxiosAuthorization,
    updateAxiosInterceptors,
    logout
}